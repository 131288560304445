import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import useCampaignData from '../../../../stores/campaignData'
import AdFormatSelector from './AdFormatSelector'
import { Button, Card } from 'react-bootstrap'
import { AnimatePresence, motion } from 'framer-motion'
import s from './ADFormatSummary.module.scss'
import useResponsive from '../../../../utils/useResponsive'
import BookingAD from './BookingsAD/BookingAD'
import InternetSelector from './InternetAD/InternetSelector'
import YandexDirectAd from './InternetAD/YandexDirectAd'
import VkAd from './InternetAD/VkAd'
import TVvideo from './TVAd/TVvideo'
import TVbanner from './TVAd/TVbanner'
import { BackToSelectorsCard } from './back-to-selectors-card/BackToSelectorsCard'
import { IndoorPresentationCard } from './indoor-presentation-card/IndoorPresentationCard'


const AdFormatSummary = ({ cn, onBack, onNextStep }) => {
	const [campaign, campaignInterface] = useCampaignData()
	const [panelShowing, setPanelShowing] = useState(true)
	const actionsPanelRef = useRef<HTMLDivElement | null>(null)
	const budgRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		const handleIntersection = (entries: IntersectionObserverEntry[]) => {
			const [entry] = entries
			if (!entry.isIntersecting) {
				setPanelShowing(true)
			} else {
				setPanelShowing(false)
			}
		}

		const actionsPanelObserver = new IntersectionObserver(
			handleIntersection,
			{
				rootMargin: '-100px 0px 0px 0px',
			}
		)

		const setObserver = () => {
			if (actionsPanelRef.current && budgRef.current) {
				actionsPanelObserver.observe(budgRef.current)
			} else {
				setTimeout(setObserver, 100)
			}
		}

		setObserver()

		return () => {
			actionsPanelObserver.disconnect()
		}
	}, [])

	const { isMobile } = useResponsive()

	const onSubmit = async () => {
		campaignInterface.patchCampaign({
			outdoor_adv_is_selected: true,
			indoor_adv_is_selected: true,
			teletarget: {
				...campaign.teletarget,
				is_selected: true,
			},
		})
		onNextStep()
	}

	const [downloadScene, setDownloadScene] = useState(-1)


	function updateBudget(scene){
		campaignInterface.patchSelected(scene)
		setDownloadScene(-1)
		setBudget({...budget, [scene]: true})
	}

	const selected = campaignInterface.selected() ?? {}

	const [budget, setBudget] = useState({
		'0': '0' in selected ? selected['1'] : false,
		'1': '1' in selected ? selected['2'] : false,
		'2': '2' in selected ? selected['3'] : false,
		'3': '3' in selected ? selected['4'] : false,
		'4': '4' in selected ? selected['4'] : false,
	})

	return (
		<>
				

				{
					downloadScene === -1 ? (
						<Card className={cn('form')}>
						<div className={s.container}>
						<div><h2>Форматы рекламы</h2>
						Выберите подходящий для вас формат рекламы</div>

										<div onClick={() => setDownloadScene(0)}>
				<BookingAD
					title="Наружная реклама"
					bookings_type="outdoor"
					budget={budget[0]}
				/>
				</div>


				<div onClick={() => setDownloadScene(1)}>
				<BookingAD
					title="Внутренняя реклама"
					bookings_type="indoor"
					budget={budget[1]}
				/>
				</div>

<div onClick={() => setDownloadScene(2)}>
				<BookingAD
					title="Общественный транспорт"
					bookings_type="transport"
					budget={budget[2]}
				/>
				</div>

<div onClick={() => setDownloadScene(3)}>
				<BookingAD
					title="Интернет реклама"
					bookings_type="internet"
					budget={budget[3]}
				/>
				</div>

<div onClick={() => setDownloadScene(4)}>
				<BookingAD
					title="ТВ реклама"
					bookings_type="tv"
					budget={budget[4]}
				/>
				</div></div>
				
				<motion.div className={s.controls}>
			<Button
			variant={'primary'}
			style={
			isMobile ? { width: '100%', marginLeft: '10px' } : {}
			}
			onClick={onSubmit}
			>
			Далее
			</Button>
			<Button variant={'secondary'} onClick={onBack}>
			Назад
			</Button>
			</motion.div></Card>
					) : (
						<div className={s.container}>
						<BackToSelectorsCard onBackClick={() => setDownloadScene(-1)}/>
						

						<IndoorPresentationCard scene={downloadScene} cn={cn}/>
						
						<motion.div className={s.controls}>
			<Button
			variant={'primary'}
			onClick={() => updateBudget(downloadScene)}
			style={
			isMobile ? { width: '100%', marginLeft: '10px' } : {}
			}
			>
			Сохранить
			</Button>
			<Button variant={'secondary'} onClick={() => setDownloadScene(-1)}>
			Назад
			</Button>
			</motion.div></div>
					)
				} 


			
			
			</>
	)
}

export default AdFormatSummary
