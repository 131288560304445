import React, { useContext, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import s from './DataUpload.module.scss'
import useResponsive from '../../../../utils/useResponsive'
import useCampaignData from '../../../../stores/campaignData'
import { prettyThousands } from '../../../_provider/Mediaformats/MediaInfo'
import TVUpload from './Tv/TVUpload'
import { Button, Card } from 'react-bootstrap'
import { AutoPostingLegalInfo } from './auto-posting-legal-info/AutoPostingLegalInfo'


const DataUpload = ({ cn, onBack, setStep: setGlobalStep }) => {
	const [campaign, campaignInterface] = useCampaignData()
	const { isMobile } = useResponsive()
	const [subPage, setSubPage] = useState(0)
	const [step, setStep] = useState(3)

	const selected = campaignInterface.selected()
	const isOutDoor = selected != undefined && '0' in selected && selected['0'] === true
	const isInDoor = selected != undefined && '1' in selected && selected['1'] === true
	const isInternet = selected != undefined && '3' in selected && selected['3'] === true
	const isTransport = selected != undefined && '2' in selected && selected['2'] === true
	const isTV = selected != undefined && '4' in selected && selected['4'] === true

	const isStepper = useMemo(
		() =>
			(isOutDoor && (isTV || isInternet || isInDoor || isTransport)) ||
			(isInDoor && (isTV || isInternet || isOutDoor || isTransport)) ||
			(isInternet && (isOutDoor || isTV || isInDoor || isTransport)) ||
			(isTV && (isOutDoor || isInternet || isInDoor || isTransport)) ||
			(isTransport && (isOutDoor || isInternet || isInDoor || isTV)),
		[isOutDoor, isInternet, isTV, isInDoor]
	)

	const DataUploadSteps = [
		{ label: 'Наружная реклама', Component: UploadElement('Наружная реклама') },
		{ label: 'Внутренняя реклама', Component: UploadElement('Внутренняя реклама') },
		{ label: 'Общественный транспорт', Component: UploadElement('Общественный транспорт') },
		{ label: 'Интернет-реклама', Component: UploadElement('Интернет-реклама') },
		{ label: 'ТВ-реклама', Component: UploadElement('ТВ-реклама') },
	]

	const DataUploadStepsOnlySelected = useMemo(() => {
		return DataUploadSteps.filter((s: any) => {
			if (s.label === 'Наружная реклама')
				if (isOutDoor) return { ...s }
			if (s.label === 'Внутренняя реклама')
				if (isInDoor) return { ...s }
			if (s.label === 'Интернет-реклама')
				if (isInternet) return { ...s }
			if (s.label === 'ТВ-реклама' && isTV) return { ...s }
			if (s.label === 'Общественный транспорт')
				if (isTransport) return { ...s }
			return null
		})
	}, [isStepper]) // eslint-disable-line react-hooks/exhaustive-deps

	function handleSubmit() {
		window.open('https://app.admarket.ru/auth/#/register')
	}

	function setNext() {
		const countSubPage = DataUploadStepsOnlySelected.length

		if(subPage + 1 < countSubPage){
			setSubPage(subPage + 1)
		} else {
			handleSubmit()
		}		
	}

	function UploadElement(label) {
		if (label === 'ТВ-реклама') {
			return (
				<TVUpload
					title={'ТВ-Реклама'}
					type="tv"
					cn={cn}
					onBack={onBack}
					onNext={() => setNext()}
					isStepper={isStepper}
					setGlobalStep={setGlobalStep}
					isLastStep={true}
					onStepBack={() => setStep(0)}
					step={step}
				/>
			)
		}

		if (label === 'Общественный транспорт') {
			return (
				<TVUpload
					title={'Общественный транспорт'}
					type="tv"
					cn={cn}
					onBack={onBack}
					onNext={() => setNext()}
					isStepper={isStepper}
					setGlobalStep={setGlobalStep}
					isLastStep={true}
					onStepBack={() => setStep(0)}
					step={step}
				/>
			)
		}

		if (label === 'Интернет-реклама') {
			return (
				<TVUpload
					title={'Интернет-реклама'}
					type="internet"
					cn={cn}
					onBack={onBack}
					onNext={() => setNext()}
					isStepper={isStepper}
					setGlobalStep={setGlobalStep}
					isLastStep={true}
					onStepBack={() => setStep(0)}
					step={step}
				/>
			)
		}

		if (label === 'Внутренняя реклама') {
			return (
				<TVUpload
					title={'Внутренняя реклама'}
					type="indoor"
					cn={cn}
					onBack={onBack}
					onNext={() => setNext()}
					isStepper={isStepper}
					setGlobalStep={setGlobalStep}
					isLastStep={true}
					onStepBack={() => setStep(0)}
					step={step}
				/>
			)
		}
		if (label === 'Наружная реклама') {
			return (
				<TVUpload
					title={'Наружная Реклама'}
					type="outdoor"
					cn={cn}
					onBack={onBack}
					onNext={() => setNext()}
					isStepper={isStepper}
					setGlobalStep={setGlobalStep}
					isLastStep={true}
					onStepBack={() => setStep(0)}
					step={step}
				/>
			)
		}
	}

	return (
		<Card className={cn('form')}>

			<RenderSubMenu
				step={subPage}
				setStep={setSubPage}
				DataUploadStepsOnlySelected={
					DataUploadStepsOnlySelected
				}
			/>
	
			<AutoPostingLegalInfo/>
		

			{DataUploadStepsOnlySelected.map((item, index) => {
				if(index === subPage){
					return item.Component
				}
			}
			)}


	
		<div className={cn('buttons')}>
				<Button
					variant={'primary'}
					onClick={setNext}
					style={
						isMobile ? { width: '100%', marginLeft: '10px' } : {}
					}
				>
					Далее
				</Button>
				<Button variant={'secondary'} onClick={() => onBack()}>
					Назад
				</Button>
			</div>
		</Card>
	)
}

export default DataUpload

export const minMax = (min, max, prefix) => {
	if (min === max) return `${prettyThousands(min)} ${prefix}`
	if (min === null && max) return `${prettyThousands(max)} ${prefix}`
	if (max === null && min) return `${prettyThousands(min)} ${prefix}`
	return `${prettyThousands(min)} - ${prettyThousands(max)} ${prefix}`
}


const RenderSubMenu = ({ step, setStep, DataUploadStepsOnlySelected }) => {
	const [domReady, setDomReady] = React.useState(false)

	React.useEffect(() => {
		setDomReady(true)
	}, [])

	const { isMobile, isTablet } = useResponsive()

	const SubMenuDesktop = () => (
		<div className={s.submenu}>
			{DataUploadStepsOnlySelected.map(({ label }, i) => (
				<div
					className={s.submenuItem}
					data-active={i <= step ? 'active' : ''}
					key={label}
					onClick={() => (i <= step ? setStep(i) : null)}
					style={{ cursor: i <= step ? 'pointer' : 'default' }}
				>
					<div className={s.circle} />
					<div className={s.label}>{label}</div>
				</div>
			))}
		</div>
	)



		return domReady ? ReactDOM.createPortal(
			<>
				{!isMobile && !isTablet && <SubMenuDesktop />}
			</>,
			document.querySelector('.create-campaign-page__stepper-block li:nth-child(3)')
		) : null

}