import React from 'react'
import { TVIcon } from '../../Step2-ADformats/TVAd/TVSelector'
import { Formik } from 'formik'
import { Card, Dropdown, Form } from 'react-bootstrap'
import s from '../DataUpload.module.scss'
import TvUploadItem from './TVUploadItem'
import useCampaignData from '../../../../../stores/campaignData'
import {
	useReusableModals,
} from '../../../../UtilityComponents/ReusableModals/ReusableModalsProvider'
import { AdIcon } from '../../Step2-ADformats/AdFormatSelector'

const TvUpload = ({
	title,
	type,
	cn,
	onBack,
	onNext,
	isStepper,
	setGlobalStep,
	isLastStep,
	onStepBack,
	step,
}) => {
	const [campaign] = useCampaignData()
	const showModal = useReusableModals()
	const handleSubmit = async (values) => {
		//do some logic

	}

	return (
		<>
			<Formik initialValues={{}} onSubmit={handleSubmit}>
				{({
					values,
					setFieldValue,
					handleSubmit,
					errors,
					setFieldError,
				}: any) => (
					<Form onSubmit={handleSubmit}>
						<Card className={s.card}>
							{!isStepper && (
								<>
									<h2 style={{ marginBottom: '8px' }}>
										Загрузка материалов
									</h2>
									<Dropdown.Divider
										className={' p-0 m-0 mb-4'}
									/>
								</>
							)}
							<div className={'d-flex justify-content-between'}>
								<h4 className={'mb-0'}>{title}</h4>
								{type === 'tv' && <TVIcon />}
								{type === 'internet' && <AdIcon name={'internet'} />}
								{type === 'indoor' && <AdIcon name={'indoor'} />}
								{type === 'outdoor' && <AdIcon name={'outdoor'} />}
							</div>
							<div className={'mt-2'} style={{ fontWeight: 400 }}>
								Загрузите необходимые материалы в соответствии с
								их техническими требованиями.
							</div>
						</Card>

						{campaign.adstream.is_selected && (
							<Card
								className={s.card}
								style={{ marginTop: '8px' }}
							>
								<TvUploadItem adName={'adstream'} />
							</Card>
						)}
						{campaign.teletarget.is_selected && (
							<>
								{campaign.teletarget.is_small_banner && (
									<Card
										className={s.card}
										style={{ marginTop: '8px' }}
									>
										<TvUploadItem
											adName={'teletarget'}
											teletarget_banner_type={
												'small_banner'
											}
										/>
									</Card>
								)}
								{campaign.teletarget.is_l_banner && (
									<Card
										className={s.card}
										style={{ marginTop: '8px' }}
									>
										<TvUploadItem
											adName={'teletarget'}
											teletarget_banner_type={'l_banner'}
										/>
									</Card>
								)}
							</>
						)}

						{/*controls buttons*/}
					</Form>
				)}
			</Formik>
		</>
	)
}

export default TvUpload
