import React, { FC, useMemo } from 'react'
import { ForeCast } from '../../../stores/ADMarketTypes.types'
import s from './mediaplan.module.scss'
import IntlFormatter from '../../../utils/intl-money-formatter'
import time from './images/time.svg'
import useCampaignData from 'src/stores/campaignData'
interface ForeCastTableProps {
	forecast: ForeCast
}

type TableItem = {
	name: string
		place: string
		show: string
		click: string
		price: string
		priceWithVAT: string
} | null

export default function ForeCastTable(props: ForeCastTableProps){
	
	const [campaign, campaignInterface] = useCampaignData()
	const selected = campaignInterface.selected() ?? {}

	const totals = {
		amount: 30000
	}

	let data: TableItem[] = [
		...['0' in selected ? {
			name: 'Наружная реклама - Максимальный охват',
			place: 'Москва',
			show: '~ 2333',
			click: '-',
			price: '21 000 ₽',
			priceWithVAT: '25 200 ₽',
		} : null],
		...['1' in selected ? {
			name: 'Внутренняя реклама',
			place: 'Экран в отделении Почты России г. Лениногорск, ул. Мурзина, 2г',
			show: '~ 840',
			click: '-',
			price: '401,87 ₽',
			priceWithVAT: '482,24 ₽',
		} : null],
		...['2' in selected ? {
			name: 'Общественный транспорт',
			place: 'Цифровые экраны на МЦК',
			show: '~ 262',
			click: '-',
			price: '10 500 ₽',
			priceWithVAT: '12 600 ₽',
		} : null],
		...['3' in selected ? {
			name: 'Интернет-реклама',
			place: 'Яндекс',
			show: '-',
			click: '2268',
			price: '11 111 ₽',
			priceWithVAT: '13 333,20 ₽',
		} : null],
		...['4' in selected ? {
			name: 'ТВ-реклама',
			place: 'Adstream',
			show: '~ 33068',
			click: '-',
			price: '11 111 ₽',
			priceWithVAT: '13 333,20 ₽',
		} : null],
	].filter((item) => item)

	const total = {
		show: data.reduce((prev, item) => 
			{
				const sum = item?.show.replace(' ', '').replace('-', '').replace('~', '') ?? 0
				return prev + Number(sum)

			}, 0),
		click: data.reduce((prev, item) => 
			{
				const sum = item?.click.replace(' ', '').replace('-', '').replace('~', '') ?? 0
				return prev + Number(sum)

			}, 0),
		price: data.reduce((prev, item) => 
			{
				const sum = item?.price.replace(' ', '').replace('-', '').replace(',', '.').replace('₽', '') ?? 0
				return prev + Number(sum)

			}, 0),
		priceWithVAT: data.reduce((prev, item) => 
			{
				const sum = item?.priceWithVAT.replace(' ', '').replace('-', '').replace(',', '.').replace('₽', '') ?? 0
				return prev + Number(sum)

			}, 0),
	}
	

	return (
		<table className={s.forecastTable}>
			<tbody>
				<tr>
					<th
						rowSpan={2}
						style={{ width: '200px', borderTopLeftRadius: '8px' }}
					>
						Формат рекламы
					</th>
					<th rowSpan={2} style={{ width: '372px' }}>
						Место размещения
					</th>
					<th
						colSpan={4}
						style={{
							borderTopRightRadius: '8px',
							textAlign: 'center',
						}}
					>
						Прогнозные показатели
					</th>
				</tr>
				<tr>
					<th style={{ width: '180px', textAlign: 'right' }}>
						Показы
					</th>
					<th style={{ width: '180px', textAlign: 'right' }}>
						Клики
					</th>
					<th style={{ width: '180px', textAlign: 'right' }}>
						Стоимость
					</th>
					<th style={{ width: '148px', textAlign: 'right' }}>
						Стоимость с НДС
					</th>
				</tr>
				{data.map((item, index) => (
					<tr key={`mediaplan-data-${index}`}>
						<td>{item?.name}</td>
						<td>{item?.place}</td>
						<td>{item?.show}</td>
						<td>{item?.click}</td>
						<td>{item?.price}</td>
						<td>{item?.priceWithVAT}</td>
					</tr>
				))}
				
				<tr>
					<th
						colSpan={2}
						style={{
							borderBottomLeftRadius: '8px',
							textAlign: 'right',
						}}
					>
						Итого:
					</th>
					<th>{total.show}</th>
					<th>{total.click}</th>
					<th>{IntlFormatter.format(total.price, true)}</th>
					<th style={{ borderBottomRightRadius: '8px' }}>
						{IntlFormatter.format(total.priceWithVAT, true)}
					</th>
				</tr>
			</tbody>
		</table>
	)
}
