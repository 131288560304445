import React, { useState } from 'react'
import { Badge, Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap'
import s from './indoorAdvHeader.module.scss'
import s2 from './external.module.scss'
import { ReactComponent as RadiusIcon } from 'src/assets/images/radius.svg'
import { ReactComponent as Location3Icon } from 'src/assets/images/location-3.svg'
import { ReactComponent as Railway2Icon } from 'src/assets/images/railway-2.svg'
import { ReactComponent as TransportIcon } from 'src/assets/images/transport.svg'
import { ReactComponent as YandexIcon } from 'src/assets/images/yandex.svg'
import { ReactComponent as VkIcon } from 'src/assets/images/vk.svg'
import { ReactComponent as ADStreamIcon } from 'src/assets/images/adstream.svg'
import { ReactComponent as TeletargetIcon } from 'src/assets/images/teletarget.svg'
import { ReactComponent as OkIcon } from 'src/assets/images/ok.svg'
import { ReactComponent as BillboardIcon } from 'src/assets/images/billboard_2.svg'
import { ReactComponent as PinIcon } from 'src/assets/images/pin-2.svg'
import { ReactNode } from 'react'
import FormLabelTooltip from 'src/components/UtilityComponents/FormLabelTooltip'
import { InternetTooltips } from '../InternetAD/InternetTooltips'
import AdMarketMultiplyDropdown from 'src/components/UtilityComponents/ADMarketMultiplyDropdown/ADMarketMultiplyDropdown'

const BookingADFullScrean: React.FC<{
	show: boolean
	close: () => void
}> = ({ show, close }) => {
	if (show) {
		return (
			<div
				onClick={() => close()}
				style={{
					width: '100%',
					height: '100%',
					position: 'fixed',
					top: '0',
					left: '0',
					right: '0',
					bottom: '0',
					background: 'url(/advertisingOnMap2.png)',
					backgroundSize: 'cover',
					zIndex: 9999,
					cursor: 'pointer',
				}}
			>
				<div
					style={{
						width: '100%',
						height: '100%',
						position: 'fixed',
						top: '0',
						left: '0',
						right: '0',
						bottom: '0',
						background: 'rgba(0,0,0,0.5)',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						color: '#fff',
						fontSize: '22px',
						fontWeight: 'bold',
						textAlign: 'center'
					}}
				>
					Тут вы будете выбирать нужный адрес и экран для вашей
					рекламы
				</div>
				<div style={{ position: 'fixed', top: '13px', right: '24px' }}>
					<img src="/close-white.svg" alt="" />
				</div>
			</div>
		)
	}

	return null
}


export const IndoorPresentationCard = ({scene, cn}) => {
	const [show, setShow] = useState(false)

	const scenes = [
		{title: 'Наружная реклама', desc: 'Выберите способ размещения'},
		{title: 'Внутренняя реклама', desc: 'Реклама внутри помещений общественного назначения — в торговых центрах, фитнес-центрах, лифтах, кинотеатрах, ресторанах.'},
		{title: 'Общественный транспорт', desc: 'Реклама на станциях и в поездах Московского центрального кольца (МЦК) и Московских Центральных Диаметров (МЦД)'},
		{title: 'Интернет-реклама', desc: 'Выберите способы показа рекламы. Реклама будет транслироваться в Яндексе и VK.'},
		{title: 'ТВ-реклама', desc: 'Реклама будет транслироваться на площадках AdStream и Teletarget.'},
	]

	const currentScene = scenes[scene] ?? scenes[0]


	return (
		<>
			{scene === 0 ? <>
				<Card className={cn('form')}>
				<div className={s.outdoorHeader}>
					<div className={s.primaryText}>
						<h4>{currentScene.title}</h4>
						<div>
							<BillboardIcon/>
						</div>
					</div>
					<p>
						{currentScene.desc}
					</p>
				</div>
			
			<div className={s.selectors}>
				<OutdoorVariantSelector icon={<RadiusIcon/>} title="Максимальный охват" description="Размещение минимум на 3 экранах. Нельзя указать время показа." isSelected={true}/>
				<OutdoorVariantSelector icon={<Location3Icon/>} title="Гарантированные показы" description='Размещение на 1 и более экранах. Можно указать время показа.' isSelected={true}/>
			</div>
			</Card>
				<Card className={cn('form')}>
				<div className={s.ourdoorMassCoverage}>
				<div className={s.ourdoorMassCoverageHeader}>
					<div className={s.ourdoorMassCoverageHeader_primary}>
						<h5>Гарантированные показы</h5>
						<Location3Icon/>
					</div>
					<p className='mb-3'>
					Данное размещение доступно минимум на 2 недели. Выгодно для тех кому нужен гарантированный показ в заданный промежуток времени на выбранном экране.
					</p>
				</div>
			</div>

			<Button
				disabled={false}
				onClick={() => setShow(true)}
			>
				<i className="bi bi-plus-lg me-2" />
				Добавить места
			</Button>

				
				
				</Card>
				<Card className={cn('form')}>
			<div className={s.ourdoorMassCoverage}>
				<div className={s.ourdoorMassCoverageHeader}>
					<div className={s.ourdoorMassCoverageHeader_primary}>
						<h5>Mаксимальный охват</h5>
						<RadiusIcon/>
					</div>
					<p>
						Распределение показов в рамках бюджета рекламной
						кампании по всем свободным экранам в выбранных городах.
						Выгодно для тех, кому важна минимальная стоимость за 1
						выход ролика.
					</p>
				</div>
			</div>

			<div style={{display: 'flex', gap: '20px', margin: '30px 0'}}>

				<Form.Group className={s.controlCity}>
					<Form.Label>{'Город'}</Form.Label>
					<Form.Control type="text" value="Москва" size="lg" disabled />

				</Form.Group>

				<Form.Group className={s.controlBudget}>
					<Form.Label>{'Бюджет на город'}</Form.Label>
					<div style={{ display: 'flex', gap: '0.5rem' }}>
						<Form.Control type="text" value="30 000,00 ₽" size="lg" disabled />
					</div>
					<div style={{marginTop: '0.25rem', color: 'rgb(108, 117, 125)', fontSize: '12px'}}>
						Минимальный бюджет: 21&nbsp;000&nbsp;₽</div>
				</Form.Group>

				<div style={{display: 'flex', alignItems: 'center', paddingTop: '12px'}}>
					<i className="bi bi-trash"></i>
				</div>


			</div>

			<Button
				disabled={false}
				onClick={() => setShow(true)}
			>
			
					<PinIcon style={{marginRight: '5px'}}/>
		
				Уточнить места
			</Button>

			<div style={{marginTop: '1.5rem', marginBottom: '1.5rem', borderTop: '1px solid #ced4da'}}></div>

			<Button
				disabled={true}
				
			>
				<i className="bi bi-plus-lg me-2" />
				Добавить еще город
			</Button>
			</Card>
			
			</> : null}
			{scene === 1 ? 
				(
					<Card className={cn('form')}>
						<div className={s.indoorPresentationCardContent}>
							<div className={s.outdoorHeader}>
								<div className={s.primaryText}>
									<h4>{currentScene.title}</h4>
									<div>
										
									</div>
								</div>
								<p>
									{currentScene.desc}
								</p>
							</div>
						</div>

						<div>
							<div className={`${s2.item} d-flex flex-column position-relative`}>
								<div className={`${s2.top} d-flex`}>
							
										<img
											src={
												'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg'
											}
											width={80}
											height={80}
											className="me-3 rounded-2"
											alt={'card'}
										/>
							
									<div
										className={`${s2.title}
											d-flex flex-column flex-grow-1`
										}
									>
										<div style={{ paddingRight: '15px' }}>
											Бизнес центр Каланчевская Плаза
										</div>
										<div className={s2.desc}>
										Экран в БЦ
										</div>
									</div>
									<div className={s2.rightContent}>
									
											<div
												className={s2.delete}
		
											>
												<i className="bi bi-trash" />
											</div>
								
									</div>
								</div>
								<div className="d-flex flex-column justify-content-between w-100">
									<div className={`${s2.cells} d-flex flex-grow-1`}>
										<div
											className={`${s2.cell} d-flex flex-column flex-grow-1`
											}
										>
											<div className={s2.cellTitle}>Рекламу увидят:</div>
											<div className="">
												~ 360 чел/день
											</div>
											<div className={s2.cellBorder}></div>
										</div>
										<div
											className={`${s2.cell} ${s2.cellMargin} d-flex flex-column flex-grow-1`}
										>
											<div className={s2.cellTitle}>
												Количество дней показа:
											</div>
											<div>
												6 дней
											</div>
										</div>
									</div>
									<div
										className={`${s2.bottom} d-flex justify-content-between`}
									>
										<div>Прогнозируемая цена:</div>
										<div>
											3 456
										</div>
									</div>
							
								</div>
							</div>
						</div>

						

						<div className='d-flex' style={{gap: '10px'}}>
							<Button
								disabled={false}
								onClick={() => setShow(true)}
								style={{width: '100%'}}
							>
								<i className="bi bi-plus-lg me-2" />
								Добавить места
							</Button>

							<Button
								style={{whiteSpace: 'nowrap'}}
								disabled={true}
								onClick={() => setShow(true)}
							>
								Удалить все
							</Button>
						</div>

						<div className={s.totals}>
							<div className={`${s.totalsItem} ${s.bb}`}>
								<div>Всего рекламных мест:</div>
								<div>1</div>
							</div>
							<div className={`${s.totalsItem} ${s.bb}`}>
								<div>Всего рекламу увидят:</div>
								<div>~ 100 чел./день</div>
							</div>
							<div className={s.totalsItem}>
								<div>Стоимость всего:</div>
								<div>3567 </div>
							</div>
						</div>
					</Card>
				) : null
			}

			{scene === 2 ? <>
				<Card className={cn('form')}>
				<div className={s.outdoorHeader}>
					<div className={s.primaryText}>
						<h4>{currentScene.title}</h4>
						<div>
							
						</div>
					</div>
					<p>
						{currentScene.desc}
					</p>
				</div>
			<div className={s.selectors}>
				<OutdoorVariantSelector icon={<Railway2Icon/>} title="Москва – МЦК" description="64 экрана на 30 станциях Московского Центрального Кольца.
Средний пассажиропоток: > 58 млн. в месяц." isSelected={true}/>
				<OutdoorVariantSelector icon={<TransportIcon/>} title="Москва – МЦД" description='Экраны на станциях и в поездах Московских Центральных Диаметров.

Временно недоступно' isSelected={false}/>
			</div>
			<div className={s.ourdoorMassCoverage}>
				<div className={s.ourdoorMassCoverageHeader}>
					<div className={s.ourdoorMassCoverageHeader_primary}>
						<h5>Цифровые экраны на МЦК</h5>
			
					</div>
					<p>
					Равномерное распределение показов по всем 64 поверхностям на 30 станциях. Выбор станций прорабатывается оператором рекламных экранов и будет доступен позже.
					</p>
				</div>
			</div>

			<div style={{display: 'flex', gap: '20px', margin: '30px 0'}}>

				<Form.Group className={s.controlBudget}>
					<Form.Label>{'Бюджет'}</Form.Label>
					<Form.Control type="text" value="30 000,00 ₽" size="lg" disabled />

				</Form.Group>

				<Form.Group className={s.controlBudget}>
					<Form.Label>{'Оплата'}</Form.Label>
					<div style={{ display: 'flex', gap: '0.5rem' }}>
						<Form.Control type="text" value="За показы" size="lg" disabled />
					</div>
				</Form.Group>


			</div>
			</Card>
			</> : null}

			{/* internet */}
			{scene === 3 ? <>
				<Card className={cn('form')}>
					<div className={s.outdoorHeader}>
							<div className={s.primaryText}>
								<h4>{currentScene.title}</h4>
								<div>
									
								</div>
							</div>
							<p>
								{currentScene.desc}
							</p>
						</div>
					<div className={s.selectors}>
						<OutdoorVariantSelector icon={<YandexIcon/>} title="Яндекс" description="Авито, Mail.ru, Яндекс.Новости, Яндекс.Дзен, Яндекс.Спорт, Gismeteo и другие" isSelected={true}/>
						<OutdoorVariantSelector icon={<VkIcon/>} title="VK" description='ВКонтакте, Одноклассники, Юла, Mail.ru, MyTarget' isSelected={true}/>
					</div>
				</Card>

				<Card className={cn('form')}>
				<Form className={'d-flex flex-column gap-4'}>
			<div className={'d-flex justify-content-between'}>
				<h5 className={'mb-0'}>VK Реклама</h5>
				<div>
					<VkIcon />
				</div>
			</div>
			<Form.Group>
				<ul className={'d-flex flex-column gap-2'}>
					<li>
						Вам понадобится рекламное изображение. Если у вас его
						нет — мы поможем с изготовлением. Соответствующая опция
						появится на следующем этапе «Загрузка материалов».
					</li>

					<li>
						Аудитория увидит ваше рекламу в социальных сетях
						ВКонтакте и Одноклассники; мобильных и десктопных
						сервисах и контентных проектах компании VK; собственной
						рекламной сети VK, куда входят сайты и приложения; сети
						NativeRoll. Полный список площадок Полный список
						площадок, вы можете посмотреть в специальном разделе на
						сайте{' '}
						<a
							href="https://target.my.com/pro/education/online-course/creating-ad-campaign/placements"
							target={'_blank'}
							rel={'noreferrer'}
						>
							VK.
						</a>
					</li>
					<li>
						Минимальная стоимость рекламной кампании напрямую
						зависит от выбранного периода (количества дней).
					</li>
				</ul>
			</Form.Group>
			<Form.Group>
				<Form.Label>Рекламируемый сайт</Form.Label>
				<Form.Control
					id={'123'}
					type={'text'}
					size={'lg'}
					value={'example.com'}
					disabled

					placeholder={'Введите ссылку на сайт'}
				/>

			</Form.Group>
	
					<Form.Group>
						<Form.Label>Целевое действие</Form.Label>
						<Form.Control
							type={'text'}
							value={'Клики по рекламе'}
							disabled
							size={'lg'}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>
							Стратегия
							<FormLabelTooltip
								text={InternetTooltips.VK.strategy}
							/>
						</Form.Label>
						<Form.Control
							type={'text'}
							value={'Максимум показов по минимальной цене'}
							disabled
							size={'lg'}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>
							Бюджет кампании
						</Form.Label>
						<Form.Control
							style={{width: '50%'}}
							type={'text'}
							value={'Максимум показов по минимальной цене'}
							disabled
							size={'lg'}
						/>
					</Form.Group>
		
	
		</Form>
				</Card>
				<Card className={cn('form')}>
				<Form className={'d-flex flex-column gap-4'}>
			<div className={'d-flex justify-content-between'}>
				<h5 className={'mb-0'}>Яндекс</h5>
				<div>
					<YandexIcon />
				</div>
			</div>
			<Form.Group>
				<ul className={'d-flex flex-column gap-2'}>
					<li>
						Вам понадобится рекламное изображение. Если у вас его
						нет — мы поможем с изготовлением. Соответствующая опция
						появится на следующем этапе «Загрузка материалов».
					</li>
					<li>
						Мы проведём единую перфоманс-кампанию, которая подходит
						для размещения рекламы большинства товаров и услуг в
						разных форматах. Она объединяет и расширяет возможности
						самых популярных и привычных кампаний Директа.
						Объявления показываются в результатах поиска и в
						Рекламной сети Яндекса, на десктопах, планшетах и
						мобильных телефонах.
					</li>
					<li>
						Аудитория увидит вашу рекламу в Рекламной сети Яндекса
						(РСЯ) — это система размещения рекламы на сервисах
						Яндекса и площадках партнеров. РСЯ открывает
						рекламодателям доступ к аудитории более чем 50 000
						площадок. Полный список площадок вы можете посмотреть в
						специальном разделе на сайте{' '}
						<a
							href="https://yandex.ru/support/direct/general/yan.html"
							target={'_blank'}
							rel={'noreferrer'}
						>
							Яндекс.
						</a>
					</li>
					<li>
						Минимальная стоимость рекламной кампании напрямую
						зависит от выбранного периода (количества дней).
					</li>
				</ul>
			</Form.Group>

			{/* <YandexCounterId /> */}
			<Form.Group>
				<Form.Label>Рекламируемый сайт</Form.Label>
				<Form.Control
					id={'asd'}
					type={'text'}
					size={'lg'}
					value={'example.com'}
					disabled
					placeholder={'Введите ссылку на сайт'}
				/>
	
			</Form.Group>

			
				<Row>
					<Form.Group as={Col}>
						<Form.Label>Бюджет кампании</Form.Label>
						<Form.Control
							type={'text'}
							value={'30 000'}
							disabled
							size={'lg'}
						/>
						
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>Оплата</Form.Label>
						<Form.Control
							type={'text'}
							value={'За клики'}
							disabled
							size={'lg'}
						/>
					</Form.Group>
				</Row>
		
		</Form>
				</Card>

			</> : null}

			{scene === 4 ? <>
				<Card className={cn('form')}>
				<div className={s.outdoorHeader}>
					<div className={s.primaryText}>
						<h4>{currentScene.title}</h4>
						<div>
							
						</div>
					</div>
					<p>
						{currentScene.desc}
					</p>
				</div>
			<div className={s.selectors}>
				<OutdoorVariantSelector icon={<ADStreamIcon/>} title="" description="Реклама в сетях операторов IPTV и SmartTV

Лидер на рынке генерации, автоматизации, и учета трафика видео - рекламы" isSelected={true}/>
				<OutdoorVariantSelector icon={<TeletargetIcon/>} title="" description='Интерактивная реклама на ТВ

Временно недоступно' isSelected={false}/>
			</div></Card>

			<Card className={cn('form')}>
			<div
				className={'d-flex justify-content-between'}
				style={{ marginBottom: '24px' }}
			>
				<h4 className={'mb-0'}>AdStream</h4>
				<div>
					<TVIcon />
				</div>
			</div>
			<Form.Group className={'mb-4'}>
			<ul className={'d-flex flex-column gap-2'}>
				<li>
					Вам понадобится рекламный видеоролик. Если у вас его нет —
					мы поможем с изготовлением. Соответствующая опция появится
					на следующем этапе «Загрузка материалов».
				</li>
				<li>
					Зрители увидят вашу рекламу при переключении на один из
					каналов выбранной Вами категории. Зрители увидят только один
					Ваш рекламный ролик.
				</li>
				<li>
					Например при бюджете 50 000 ₽ на 2 недели вашу рекламу
					покажут примерно 179 000 раз около 12 000 потенциальных
					клиентов. А при бюджете 100 000 ₽ на 2 недели — около 357
					000 показов рекламы примерно для 25 000 потенциальных
					клиентов.
				</li>
				<li>
					Укажите каналы для показа, бюджет и мы подготовим точный
					расчет вашей рекламной кампании на ТВ.
				</li>
			</ul>

			<InputGroup
				hasValidation
				style={{ marginBottom: '24px' }}
			>
				<Form.Label>Каналы для показа</Form.Label>
				<AdMarketMultiplyDropdown

					list={['Федеральные каналы']}
					error={''}
					selected={['Федеральные каналы']}
					onSelectedChange={(sectionsNames) => {}
					}
					noCheck
					additionalInfo={[]}
					withSeparator
				/>

				
			</InputGroup>

			<Form.Group className={s.controlBudget}>
					<Form.Label>{'Бюджет'}</Form.Label>
					<Form.Control type="text" value="30 000,00 ₽" size="lg" disabled />

				</Form.Group>
		</Form.Group>
			</Card>

			</> : null}
			

			<BookingADFullScrean show={show} close={() => setShow(false)} />
				
		</>
	)
}


type Props = {
	title: string
	isSelected: boolean
	description: string
	icon?: ReactNode
}
export const OutdoorVariantSelector = ({
	title,
	isSelected,
	description,
	icon
}: Props) => {

	return (
		<div
			className={`${isSelected ? s.format_selected : s.format} `}
		>
			<div className={s.icon}>
				{isSelected ? <OkIcon/> : (icon ? icon : null)}
			</div>
			<div className={s.title}>
				<div>{title}</div>
				<span>{description}</span>
			</div>
		</div>
	)
}


const TVIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M5 18a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-6v2h3v2H8v-2h3v-2H5Zm-1-3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v10Zm6.573-9.32A1 1 0 0 0 9 6.5v7a1 1 0 0 0 1.573.82l5-3.5a1 1 0 0 0 0-1.64l-5-3.5ZM13.256 10 11 11.58V8.42L13.256 10Z"
			clipRule="evenodd"
		/>
	</svg>
)