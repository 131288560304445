import React from 'react'
import { AdIcon } from '../AdFormatSelector'
import {
	PlacementShort,
	PlacementShowingCountType,
	PlacementType,
} from '../../../../../stores/ADMarketTypes.types'
import s from './BookingAD.module.scss'
import { ReactComponent as OkIcon } from 'src/assets/images/ok.svg'


export type PlacementWithOnlyOneShowingCount = Omit<
	PlacementType,
	'placement_showing_counts'
> & { placement_showing_count: PlacementShowingCountType }

export interface PlacementShortWithStateAndFocus extends PlacementShort {
	name: string
	focus: null | 'focus'
	state: 'selected' | 'default' | 'unactive'
	placement_detail?: PlacementWithOnlyOneShowingCount | undefined
}

const BookingAD: React.FC<{
	bookings_type: 'indoor' | 'outdoor' | 'tv' | 'internet' | 'transport'
	title: string
	budget: boolean
}> = ({ bookings_type, title, budget }) => {



	return (
			<div className={s.item}>
				<div>
					<AdIcon name={bookings_type} /> 
					<div>{title}</div>
				</div>
				{budget === true ? 
					<>
						<div style={{display: 'flex', gap: '10px'}}>
							<div className={s.budget}>{budget ? 30000 : false} ₽</div>

							<span className={s.status}>
								<OkIcon />
								Добавлено
							</span>
						</div>
					</>
				 : null}
				
			</div>
	)
}

export default BookingAD
